import consumer from './consumer'

consumer.subscriptions.create('RecommendedDimensionsChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        if ( $('[data-container="' + data.project.to_html_token + '"]').length > 0 ) {
            this.updateTable(data);
        }
    },

    updateTable(data) {
        $('#dimensions-table').html(data.html);
    },
});
