import 'jquery';
import 'jquery-ui-bundle';
import axios from "axios";

$(function () {
    function updateDomainType(id, value) {
        axios.patch(`/project_domain_types/${id}`, { domain_type: value })
            .then((response) => {
                $('#selected-domain-count').text(response.data.stats);
            })
            .catch(() => showError('Something went wrong!'));
    }

    function domainTypeLimit(domainType) {
        return domainType === 'excluded' ? 100 : 10;
    }

    function domainTypeLimitExceed(id, value) {
        let limit = domainTypeLimit(value);
        let count;
        $.ajax({
            async: false,
            url: '/project_domain_types/domain_types_count',
            type: 'GET',
            data: { id: id, domain_type: value },
            success: function(data) {
                count = data.count;
            }
        });
        if (count === undefined) {
            return true
        }
        return count >= limit;
    }

    let domainTypeField = function(domainType) {
        return {
            data: 'domain_type',
            fnCreatedCell: function (elem, field, obj) {
                let limitExceed = false;
                let typesWithoutLimit = ['not_selected'];
                let $input = $('<input/>', {
                    type: 'radio',
                    value: domainType,
                    name: `domain_type_${obj.id}`,
                    click: function() {
                        limitExceed = typesWithoutLimit.includes(domainType) ? false : domainTypeLimitExceed(obj.id, domainType)

                        if (limitExceed) {
                            showError(`Max ${domainTypeLimit(domainType)} ${domainType} domains can be selected`);
                            return false;
                        } else {
                            updateDomainType(obj.id, domainType);
                        }
                    }
                }).prop('checked', obj.domain_type === domainType);
                $(elem).html($input);
            },
            orderable: false
        }
    }

    let competitorsTable = $('#competitors-table').DataTable({
        ajax: {
            url: $('#competitors-table').data('url'),
            data: function (data) {
                data.domain_type = $('.js-domain-type-filter:checked').val();
                return data;
            }
        },
        processing: true,
        serverSide: true,
        order: [[1, 'desc']],
        columns: [
            {
                data: 'domain',
                orderable: false,
                fnCreatedCell: function (elem, field, obj, row, col) {
                    let domain = obj.domain;
                    $(elem).html(`<a href="${domain}">${domain}</a>`);
                }
            },
            { data: 'rank_1_3', orderable: true, orderSequence: ['desc','asc'] },
            { data: 'rank_4_10', orderable: true, orderSequence: ['desc','asc'] },
            { data: 'rank_11_20', orderable: true, orderSequence: ['desc','asc'] },
            domainTypeField('client'),
            domainTypeField('competitor'),
            domainTypeField('excluded'),
            domainTypeField('not_selected')
        ],
        oLanguage: {
            sSearch: 'Search domains: '
        }
    });

    $('.js-domain-type-filter').change(function (e) {
        competitorsTable.draw();
    });
});
