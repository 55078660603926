function loadingStarted($button) {
    $button.attr('disabled', true);
    let loadingIcon = $button.parent().find('.loading-icon');
    loadingIcon.show();
}

function loadingEnded($button) {
    $button.removeAttr('disabled');
    let loadingIcon = $button.parent().find('.loading-icon');
    loadingIcon.hide();
}

export { loadingStarted, loadingEnded };