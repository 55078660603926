import axios from 'axios';
import 'jquery';
import 'select2';
import { initSelect2Field } from './common';

const DELAY_TIME = 600;
let delayTimer;

const getProjects = (url, name, term) => {
    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        params.set(name, term);
        axios.get(url, { params: params })
             .then((response) => {
                 $('#projects-table').html(response.data);
                 history.replaceState({}, '', `${url}?${params.toString()}`);
             })
             .catch(() => showError('Something went wrong!'))
    }, DELAY_TIME);
};

const setSelect2DefaultValue = ($select2Field, defaultRecord) => {
    if (Object.keys(defaultRecord).length > 0) {
        let defaultOption = new Option(defaultRecord.name, defaultRecord.id, true, true);
        $select2Field.append(defaultOption).trigger('change');
    }
};

$(document).on('input', '.js-projects-search', function(){
    const url = $(this).closest('form').attr('action');
    const term = $(this).val();
    const name = $(this).attr('name');

    getProjects(url, name, term);
});

$(function() {
    initSelect2Field('#project-search', '/api/v1/projects');
    initSelect2Field('#client-search', '/api/v1/clients');

    $('#project-clone').click(function(){
        let $cloningForm = $(this).closest('#cloning-form');
        let $client = $cloningForm.find('#client-search');
        let $project = $cloningForm.find('#project-search');
        let url = `/clients/${$client.val()}/projects/${$project.val()}/clone`;
        axios.post(url)
            .then(function(response){
                showInfo('Project cloning started successfully');
            }, function(error){
                showError('Project cloning failure');
            })
            .finally(function (){
                $client.empty();
                $project.empty();
                $cloningForm.modal('hide');
            });
    });

    $('#cloning-form').on('shown.bs.modal', function () {
        let $client = $(this).find('#client-search');
        let $project = $(this).find('#project-search');
        let defaultClient = $client.data('default-record');
        let defaultProject = $project.data('default-record');
        setSelect2DefaultValue($client, defaultClient);
        setSelect2DefaultValue($project, defaultProject);
    });

    $('.js-menu-project-cloning').on('click', function (){
        let $project = $('#cloning-form').find('#project-search');
        let defaultProject = $(this).data('default-record');
        setSelect2DefaultValue($project, defaultProject)
    })

    $(document).on('ajax:success', '.js-adwords-request', function () {
        showInfo('Adwords request created successfully');
        return false;
    });

    $(document).on('ajax:success', '#click-rates-category-form', function () {
        showInfo('Project was successfully updated.');
        return false;
    });

    $(document).on('ajax:success', '#project-advanced-settings-form', function () {
        location.reload();
        return false;
    });

    $(document).on('ajax:error', '.js-adwords-request, #click-rates-category-form, #project-advanced-settings-form', function () {
        showError('Something went wrong!');
        return false;
    });

    $('select#project_click_rate_category_id').on('change', function () {
        let $listContainer = $('ul.click-rates-list');
        let $selectedOption = $(this).find(":selected");
        $listContainer.empty();
        let rates = $selectedOption.data('rates').split(',');
        rates.forEach((rate, idx) => $listContainer.append(`<li>#${idx + 1}&nbsp;&nbsp;&nbsp;${rate}%</li>`))
    });
});
