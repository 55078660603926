import axios from 'axios';
import 'jquery';
import { errorMessage } from './common';

$(document).on('click', '.js-task-create', function() {
    const $this = $(this);

    axios.post($this.attr('href'))
        .then(function(){
            showInfo('Segmentation started');
        })
        .catch(function(error){
            let message = errorMessage(error, 'Segmentation start failure');
            showError(message);
        })
        .finally(function (){
            $this.closest('.open').trigger('click');
        });
    return false;
});

$(document).on('click', '.js-task-update', function() {
    const $this = $(this);

    axios.put($this.attr('href'))
        .then(function(){
            showInfo('Segmentation started');
        })
        .catch(function(error){
            let message = errorMessage(error, 'Segmentation start failure');
            showError(message);
        })
        .finally(function (){
            $this.closest('.open').trigger('click');
        });
    return false;
});

$(document).on('click', '.js-task-remove', function() {
    const $this = $(this);
    const done = confirm('Remove task?');

    if (done) {
        axios.delete($this.attr('href'))
            .then(function () {
                $this.closest('tr').remove();
            })
            .catch(function () {
                showError('Something went wrong!')
            });
    }
    return false;
});

const loadTasks = (url, popupSelector) => {
    axios.get(url)
        .then(function(response){
            $(popupSelector).find('.modal-body').html(response.data);
            $(popupSelector).modal('show');
        })
        .catch(function() {
            showError('Something went wrong!');
        });
};

$(document).on('click', '.js-tasks-history', function(){
    loadTasks($(this).attr('href'), '#tasks-history');
    return false;
});

$(document).on('click', '.js-working-tasks', function(){
    loadTasks($(this).attr('href'), '#working-tasks');
    return false;
});

$(document).on('click', '#working-tasks .pagination a', function (){
    loadTasks($(this).attr('href'), '#working-tasks');
    return false;
});

$(document).on('click', '#tasks-history .pagination a', function (){
    loadTasks($(this).attr('href'), '#tasks-history');
    return false;
});

$(document).on('click', '.js-task-stop', function() {
    const $this = $(this);
    const done = confirm('Stop process?');

    if (done) {
        axios.post($this.attr('href'))
            .then(function(){
                const task = $this.closest('.task-container').data('task');

                //remove progress list
                $(`.task-container[data-task="${task}"]`).remove();

                //remove global progress list
                $('#working-tasks .tasks-list:empty').closest('tr').remove();
            })
            .catch(function(){
                showError('Something went wrong!')
            });
    }
    return false;
});