import axios from 'axios';

$(function(){
    if ( $('#selected-libraries').length <= 0 ) {
        return
    }

    var provider = (function(){
        let url = window.location.href.split('?')[0];
        let targetUrl = url.replace('recommended_dimensions', 'selected_libraries');

        return {
            create: function(library) {
                return axios.post(targetUrl, { library_id: library.id, regex_type: library.regex_type })
            },

            destroy: function(library) {
                return axios.delete(targetUrl, { params: { library_id: library.id, regex_type: library.regex_type } })
            },

            destroyAll: function() {
                return axios.delete(targetUrl)
            },

            load: function() {
                return axios.get(targetUrl)
                            .then((response) => {
                                return response.data
                            });
            }
        }
    })();

    var dataSource = (function() {
        let items = new Map();
        let storage = undefined;

        function draw(library) {
            drawPanel();

            if (library) {
                drawLibrary(library);
            }
        }

        function drawPanel() {
            if (items.size > 0) {
                $('.selected-libraries span').text('Selected dimensions (' + items.size + ')')
            } else {
                $('.selected-libraries span').text('Selected dimensions')
            }
        }

        function drawLibrary(library) {
            let persisted = items.get(library.id + '_' + library.regex_type);
            $('input[type="checkbox"][name="library_' + library.id + '_' + library.regex_type + '"').prop('checked', persisted ? true : false);
        }

        function add(library) {
            return storage.create(library)
                            .then(() => {
                                items.set(library.id + '_' + library.regex_type, library);
                            })
        }

        function del(library) {
            return storage.destroy(library)
                .then(() => {
                    items.delete(library.id + '_' + library.regex_type);
                })
        }

        function load() {
            return storage.load()
                    .then((libraries) => {
                        items.clear();

                        for (let library of libraries) {
                            items.set(library.id + '_' + library.regex_type, library);
                        }
                    })
        }

        return {
            init: function(provider) {
                storage = provider;

                load()
                    .catch(() => {
                        showError('Something went wrong!')
                    })
                    .finally(() => {
                        draw()
                    });
            },

            create: function(library) {
                add(library)
                    .then(() => {
                        draw(library)
                    })
                    .catch(() => {
                        showError('Something went wrong!')
                    });
            },

            delete: function(library) {
                del(library)
                    .then(() => {
                        draw(library)
                    })
                    .catch(() => {
                        showError('Something went wrong!')
                    });
            },

            deleteAll: function() {
                storage.destroyAll()
                        .then(() => {
                            items.clear();
                            $('input[type="checkbox"][id^="library_"]').prop('checked', false);

                            draw()
                        })
                        .catch(() => {
                            showError('Something went wrong!')
                        });
            },

            each: function(func) {
                items.forEach(func);
            },
        }
    })();
    dataSource.init(provider);

    var popup = (function () {
        let el = $('#selected-libraries');
        let table = undefined;
        let storage = undefined;

        function data() {
            let records = new Map();

            storage.each(function(value, key){
               let item = records.get(value.id);

                item = { broad: false, exact: false, phrase: false, ...item, id: value.id, name: value.name };
                item[value.regex_type] = true;

                records.set(item.id, item);
            });

            return Array.from(records.values());
        }

        function update(el, value) {
            $(el).prop('checked') ? storage.create(value) : storage.delete(value)
        }

        return {
            init: function(source) {
                storage = source;

                $(el).on('hidden.bs.modal', function () {
                    if (table) {
                        table.destroy();
                    }
                });

                $(el).on('click', '.js-recommended-clear', function(){
                    storage.deleteAll();
                    $(el).modal('hide');
                    return false;
                });
            },

            show: function() {
                table = $(el).find('.modal-body table').DataTable({
                    data: data(),
                    columns: [
                        { title: "Library", data: "name" },
                        { title: "Broad Match", data: "broad",
                            fnCreatedCell: function (elem, field, obj, row, col) {
                                let input = $('<input/>', {
                                    type: 'checkbox',
                                    click: function() {
                                        update(this, {id: obj.id, name: obj.name, regex_type: 'broad'});
                                    }
                                }).prop('checked', field);
                                $(elem).html(input);
                            }
                        },
                        { title: "Exact Match", data: "exact",
                            fnCreatedCell: function (elem, field, obj, row, col) {
                                let input = $('<input/>', {
                                    type: 'checkbox',
                                    click: function() {
                                        update(this, {id: obj.id, name: obj.name, regex_type: 'exact'});
                                    }
                                }).prop('checked', field);
                                $(elem).html(input);
                            }
                        },
                        { title: "Phrase Match", data: "phrase",
                            fnCreatedCell: function (elem, field, obj, row, col) {
                                let input = $('<input/>', {
                                    type: 'checkbox',
                                    click: function() {
                                        update(this, {id: obj.id, name: obj.name, regex_type: 'phrase'});
                                    }
                                }).prop('checked', field);
                                $(elem).html(input);
                            }
                        },
                        { title: "Action", data: "id",
                            fnCreatedCell: function (elem, field, library, row, col) {
                                let link = $('<a/>', {
                                    class: "selected-library",
                                    html: 'Delete',
                                    click: function() {
                                        if (confirm('Delete library?')) {
                                            storage.delete({id: library.id});

                                            if (table) {
                                                table.row($(this).closest('tr')).remove().draw(false);
                                            }
                                        }
                                        return false
                                    }
                                });
                                $(elem).html(link);
                            }
                        }
                    ],
                    lengthChange: false,
                    searching: false,
                    info: false
                });

                $(el).modal('show');
            },

            hide: function() {
                $(el).modal('hide');
            }
        }

    })();
    popup.init(dataSource);

    $(document).on('click', '.js-recommended-use', function(){
        let library = {
            id: $(this).closest('tr').data('library-id'),
            name: $(this).closest('tr').data('library-name'),
            regex_type: $(this).val()
        };
        $(this).prop('checked') ? dataSource.create(library) : dataSource.delete(library);
        return false
    });

    $(document).on('click', '.js-selected-libraries', function(){
        popup.show();
        return false
    });
});