import consumer from './consumer'

consumer.subscriptions.create('ProjectDomainsChannel', {
    received(data) {
        let $competitorsTable =  $('#competitors-table');
        if($competitorsTable.length > 0 && $competitorsTable.data('project-id') == data.project_id) {
            let task = data.task;
            if (task.status === 'failure' || task.status === 'success' || task.status == 'cancel') {
                $competitorsTable.DataTable().ajax.reload();
                $('#competitors-table_wrapper').show();
                $('#competitors-loader').hide();
            }

            if (task.status === 'waiting' || task.status === 'running') {
                $('#competitors-table_wrapper').hide();
                $('#competitors-loader').show();
                return this.updateTask(data);
            }

        }
    }
});
