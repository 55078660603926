import consumer from './consumer'

consumer.subscriptions.create('DimensionLibraryChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateLink(data);
    },

    updateLink(data) {
        let dimension = data.dimension;
        let $dimension = $(`[data-dimension=${dimension.id}]`);
        $dimension.find('.dimension-library').html(data.html);
    }
});
