import axios from 'axios';
import 'jquery';

const DELAY_TIME = 600;
let delayTimer;

const getRecommendedDimensions = (url, name, term) => {
    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        params.delete('page');
        params.set(name, term);
        axios.get(url, { params: params })
             .then((response) => {
                 $('#recommended-table').html(response.data);
                 history.replaceState({}, '', `${url}?${params.toString()}`);
             })
             .catch(() => showError('Something went wrong!'))
    }, DELAY_TIME);
};

$(document).on('input', '.js-recommended-search', function(){
    const url = $(this).closest('form').attr('action');
    const term = $(this).val();
    const name = $(this).attr('name');

    getRecommendedDimensions(url, name, term);
});

$(document).on('ajax:success', '.js-libraries-matching', function () {
    showInfo('Libraries matching started');

    if ( $(this).closest('#recommended-table').length > 0 ) {
        $(this).remove()
    }

    return false
});

$(document).on('ajax:error', '.js-libraries-matching', function () {
    showError('Something went wrong!');
    return false;
});