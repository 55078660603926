import consumer from './consumer'

consumer.subscriptions.create('GlobalProcessesChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateCounter(data);
        this.updateProgresses(data);
    },

    updateCounter(data) {
        $('#processes-global-counter').html(data.counter);
    },

    updateProgresses(data) {
        $('#processes-global-list').html(data.html);
    }
});
