import 'jquery';
import axios from "axios";

$(function() {
    $(document).ready(function() {
        const dimensionId = $('.js-dimension-id').data('dimension');

        $('.js-phrases-frequency').click((e) => {
            axios.get('/remaining_phrases', { params: { dimension_id: dimensionId }})
                .then((response) => {
                    $('#phrase-frequency-panel').html('')
                    const data = response.data;
                    if (data.exist) {
                        showRemainingPhrases(data.html);
                    } else {
                        showInfo('Phrases not found!');
                    }
                })
                .catch(() => showError('Incompatible process is already running or something went wrong!'));
        });

        const showRemainingPhrases = (html) => {
            $('#phrase-frequency-panel').html(html);
            let $phrasesTable = $('.js-remaining-phrases-table');
            $phrasesTable.DataTable({
                'ajax': `/terms_reports/${$phrasesTable.data('report')}`,
                'processing': true,
                'serverSide': true,
                'columns': [
                    { 'data': 'phrase', 'orderable': false },
                    { 'data': 'word_count', 'orderable': false },
                    { 'data': 'frequency' },
                    { 'data': 'total_volume', 'orderable': false }
                ],
                'order': [[ 2, 'desc' ]]
            });
        };
    });
});

