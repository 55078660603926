import 'jquery';
import axios from "axios";

$(function() {
    $(document).ready(function() {
        const dimensionId = $('.js-dimension-id').data('dimension');

        $('.js-remaining-keywords').click((e) => {
            axios.get('/remaining_keywords', { params: { dimension_id: dimensionId }})
                .then((response) => {
                    const data = response.data;
                    let $keywordsPanel = $('#remaining-keywords-panel');
                    $keywordsPanel.html('');
                    if (data.count > 0) {
                        $keywordsPanel.html(data.html);
                    } else {
                        showInfo('Keywords not found!');
                    }
                })
                .catch(() => showError('Incompatible process is already running or something went wrong!'));
        });

        $(document).on('click', '.js-remaining-keywords-delete', (e) => {
            if (confirm('Are you sure?')) {
                axios.delete('/remaining_keywords', { data: { dimension_id: dimensionId }})
                    .then((response) => {
                        $('#remaining-keywords-panel').html('');
                        showInfo('Keywords deleted');
                    })
                    .catch(() => showError('Something went wrong!'));
            } else {
                e.preventDefault();
            }
            return false;
        });

        const loadRemainingItems = ($el, url) => {
            $('.pagination a').css('pointer-events', 'none');
            axios.get(url)
                .then((response) => {
                    $el.html(response.data.html);
                })
                .catch((e) => console.log(e))
                .finally(() => {
                    $('.pagination a').css('pointer-events', '');
                });
        }

        $(document).on('click', '#remaining-keywords-panel .pagination a', (e) => {
            let $this = $(e.currentTarget);
            loadRemainingItems($('#remaining-keywords-panel'), $this.attr('href'));
            return false;
        });

        $(document).on('click', '#phrase-frequency-panel .pagination a', (e) => {
            let $this = $(e.currentTarget);
            loadRemainingItems($('#phrase-frequency-panel'), $this.attr('href'));
            return false;
        });
    });
});

