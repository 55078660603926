$(function() {
    $('.nav-tabs a').on('shown.bs.tab', function (e) {
        window.location.hash = e.target.hash;
    });

    let hash = window.location.hash.replace(/^#/, '');
    if (hash) {
        $('.nav-tabs a[href="#' + hash + '"]').tab('show');
    }
});

function initSelect2Field(selector, path) {
    $(selector).select2({
        theme: 'bootstrap',
        minimumInputLength: 3,
        ajax: {
            url: path,
            delay: 250,
            dataType: 'json',
            processResults: function(data){
                return { results: data }
            }
        }
    });
}

function errorMessage(error, defaultMessage) {
    let message = error.response.data.alert;
    if (message == undefined) { message = defaultMessage }
    return message
}
export { initSelect2Field, errorMessage };