import consumer from './consumer'

consumer.subscriptions.create('LibraryProgressChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateProgress(data);
    },

    updateProgress(data) {
        let task = data.task;

        if (task.status === 'failure' || task.status === 'success' || task.status == 'cancel') {
            return this.removeTask(data);
        }

        if (task.status === 'waiting' || task.status === 'running') {
            return this.updateTask(data);
        }
    },

    removeTask(data) {
        let task = data.task;
        let $task = $(`[data-task=${task.id}]`);

        $task.remove();
    },

    updateTask(data) {
        let task = data.task;
        let $containers = $(`.tasks-list[data-container="${data.library.to_html_token}"]`);

        $containers.each(function(_, container){
            let $task = $(container).find(`[data-task=${task.id}]`);

            if ($task.length > 0) {
                $task.find('.progress-bar').css({width: `${task.progress}%`});
                $task.find('.progress-bar-message').text(task.message);
            } else {
                $(container).append(data.html);
            }
        });
    }
});
