import consumer from './consumer'

consumer.subscriptions.create('ProjectTasksHistoryChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateButton(data);
    },

    updateButton(data) {
        if (data.task.finished) {
            let project = data.project;
            let $project = $(`[data-project=${project.id}]`);
            let buttonColor = data.task.status == 'success' ? 'success' : 'danger';
            $project.find('.js-tasks-history').removeClass('btn-success btn-danger').addClass('btn-' + buttonColor);
        }
    }
});
