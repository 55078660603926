import 'jquery';
import 'jquery-ui-bundle';
import axios from "axios";
import qs from 'qs';
import { loadingStarted, loadingEnded } from './loading-icon';

$(function () {
    $(document).ready(function () {
        $(document).on('click', '.js-libraries-filter', function () {
            let $this = $(this);
            $('#libraries_filter').data('value', $this.data('value'));
            $('#libraries_filter').data('predicate', $this.data('predicate'));
            loadLibraries($('#libraries-container').data('src'));
            $('#libraries-filter-btn').html(`${$this.text()}<span class="caret"></span>`);

        });

        $(document).on('click', '.js-library-search', function () {
            loadLibraries($('#libraries-container').data('src'));
            return false;
        });

        $(document).on('click', '.js-clean-library-search', function () {
            $('#search_query').val('');
            loadLibraries($('#libraries-container').data('src'));
            return false;
        });

        $(document).on('click', '#libraries-container .pagination a', function () {
            loadLibraries($(this).attr('href'));
            return false;
        });

        const loadLibraries = (url) => {
            let params = {};
            let searchQuery = $('#search_query').val();
            let filterPredicate = $('#libraries_filter').data('predicate');
            let filterValue = $('#libraries_filter').data('value');
            let $loadingIcon = $('.library-actions .loading-icon');

            if (searchQuery) {
                params['q[name_i_cont]'] = searchQuery
            }
            if (filterPredicate) {
                params['q[' + filterPredicate + ']'] = filterValue
            }

            loadingStarted($loadingIcon);
            axios.get(url, {
                params: params,
                paramsSerializer: params => {
                    return qs.stringify(params)
                }})
                .then((response) => {
                    let $libraries = $('#libraries-container');
                    const data = response.data;
                    $libraries.html(data.html);
                    $('.js-total-entries').text(data.total_entries);
                })
                .catch(() => showError('Something went wrong!'))
                .finally(() => {
                    loadingEnded($loadingIcon);
                });
        };
    });
});

