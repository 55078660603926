import axios from 'axios';
import 'jquery';
import 'select2';
import { initSelect2Field } from "./common";

$(function() {
    $(document).on('select2:open', (event) => {
        const target = document.querySelector(`[aria-controls=select2-${event.target.id}-results]`);
        target.focus();
    });

    initSelect2Field('#dimension-search', '/api/v1/dimensions')

    $("#import-segments-form").on("show.bs.modal", function(e) {
        $(this).find(".modal-body").load($(e.relatedTarget).attr("href"));
    });

    $('#dimension-clone').click(function(){
        let projectId = $(this).attr('data-project');
        let clientId = $(this).attr('data-client');
        let dimensionId = $('#dimension-search').val();
        let url = `/clients/${clientId}/projects/${projectId}/dimensions/${dimensionId}/clone`;
        axios.post(url)
            .then(function(response){
                showInfo('Clone successful');
                $('#dimensions-table').find('tbody').append(response.data);
            })
            .catch(function(){
                showError('Clone failure');
            })
    });

    $('#dimension-form').submit(() => {
        $('.js-priority-position').each((idx, item) => item.value = idx);
    });

    const getSegments = () => {
        // TODO: this method incorrect, fix it
        let selector = $('.js-dimension-segments-hide').is(':visible') ? '.nested-fields:visible' : '.nested-fields';
        return $('#segments').find(selector).filter((idx, block) => block.style.display != 'none');
    };

    const findDuplicates = (arr) => {
        let sortedArray = arr.slice().sort();
        let results = [];
        for (let i = 0; i < sortedArray.length - 1; i++) {
            if (sortedArray[i + 1] == sortedArray[i]) {
                results.push(sortedArray[i]);
            }
        }
        return results;
    };

    const setDuplicatedSegments = () => {
        let names = getSegments().map((idx, el) => $(el).find('.dimension_segments_name input')[0].value);
        let duplicates = findDuplicates(names);
        let duplicatedSegments = $('#duplicated-segments');
        duplicatedSegments.text('');
        if(duplicates.length > 0) {
            duplicatedSegments.append('<span>Duplicated segments</span>');
            duplicates.forEach(d => duplicatedSegments.append(`<span class='label label-danger'>${ d.length == 0 ? ' ' : d }</span>`));
        }
    };

    setDuplicatedSegments();

    $(document).on('input', '.js-segments-name', () => setDuplicatedSegments());

    const initToggleButtons = (base, el, onClick = () => {})=> {
        let showBtn = `.${base}-show`;
        let hideBtn = `.${base}-hide`;
        let $el = $(el);

        $(hideBtn).click(() => {
            $(hideBtn).hide();
            $(showBtn).show();
            $el.hide();
            onClick();
        });

        $(showBtn).click(() => {
            $(hideBtn).show();
            $(showBtn).hide();
            $el.show();
            onClick();
        });
    };

    initToggleButtons('js-segments-actions', '#segments-actions');

    $(document).on('input', '#dimension_segments_list', (event) => {
        let value = event.target.value;
        $('.js-import-segments').attr('disabled', value.trim().length == 0);
    });

    $('.js-import-segments').on('click', function(){
        $(this).closest('.modal-content').find('form.edit_dimension').submit();
    });
});
