import consumer from './consumer'

consumer.subscriptions.create('ProjectDimensionsActionsChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateMenu(data);
    },

    updateMenu(data) {
        let project = data.project;
        let $actionsContainer = $(`[data-project-dimensions=${project.id}]`);
        $actionsContainer.find('ul.dropdown-menu').replaceWith(data.html);
    }
});
