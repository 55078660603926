import consumer from './consumer'

consumer.subscriptions.create('SegmentsStatsChannel', {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    this.updateMatchedCounters(data.segment_ids, data.count)
  },

  updateMatchedCounters(segmentIds, matchedCount) {
    segmentIds.forEach(segmentId => {
      let segmentField = $('.js-segment-field[data-id="'+ segmentId + '"]')
      if(segmentField) segmentField.find('.js-segments-matched-count').text(matchedCount)
    })
  },
})
