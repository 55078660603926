import consumer from './consumer'

consumer.subscriptions.create('DimensionActionsChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateMenu(data);
    },

    updateMenu(data) {
        let dimension = data.dimension;
        let $dimension = $(`[data-dimension=${dimension.id}]`);
        $dimension.find('ul.dropdown-menu').replaceWith(data.html);
    }
});
