import 'jquery';
import axios from 'axios';
import path from 'path';
import { errorMessage } from './common';
const LineNavigator = require('line-navigator');

$(function(){
    const $importList = $('#import-list');
    const $importColumns = $('#import-columns');
    const $processKeywords = $('.js-process-keywords');

    $(document).on('change', '.import-file', function () {
        let file = this.files[0];
        if ( !isCSV(file) ) {
            showError('Invalid file type selected');
            return false
        }

        let $el = createUpload(this, file);
        $el.on('click', '.js-import-remove', function(){
            $el.remove();
            $importColumns.hide();
            $processKeywords.hide();

            return false;
        });
    });

    function isCSV(file) {
        let extname = path.extname(file.name);
        return extname.toLowerCase() === '.csv'
    }

    function createUpload(input, file){
        let $this = $(input);

        let $clone = $this.clone().val('');
        $this.after($clone);

        let $el = $(`
          <tr>
            <td>
              <strong>${file.name}</strong>
            </td>
            <td nowrap="nowrap">
              <div class="btn-group btn-group-sm btn-group-progress">
                <a class="btn btn-danger js-import-remove"><i class="mdi mdi-delete"></i>Remove</a>
              </div>
            </td>
          </tr>
        `);
        $el.find('td:first-child').append($this);
        $importList.html($el);
        $importColumns.show();
        $processKeywords.show();
        let navigator = new LineNavigator(file);
	    navigator.readLines(0, 1, function (err, index, lines, isEof, progress) {
              let options = lines[0].replaceAll('"', '')
                                    .split(',')
                                    .map((h, i) => `<option value="${i}">${h}</option>`)
                                    .join();
              let optionsWithNull = `<option value="">Select a value</option>${options}`
              let selects = $importColumns.find('.js-import-columns');
              selects.html('');
              selects.each(function() {
                  if(this.id == 'keywords-field-select') {
                      $(this).append(options);
                  } else {
                      $(this).append(optionsWithNull);
                  }
              });
	    });
        return $el;
    }

    $('#upload-rankings').on('click', function () {
        let $input = $(this).find('input');
        let $uploadColumns = $('#upload-rankings-columns');
        if($input.prop('checked')) {
            $uploadColumns.hide();
            $input.prop('checked', false);
        } else {
            $uploadColumns.show();
            $input.prop('checked', true);
        }
        return false;
    })

    $(document).on('ajax:success', '#project-keywords-import-form', function () {
        showInfo('Keywords import started.');
        $('#project-keywords-import-form').find('select').removeClass('is-invalid');
        return false;
    });

    $(document).on('ajax:error', '#project-keywords-import-form', function (event) {
        if(event.detail[2].status == 422) {
            const data = event.detail[0];
            Object.keys(data.project).forEach((field) => {
                $(`select[name='project[${field}]']`).addClass('is-invalid');
            });
        } else {
            showError('Something went wrong!');
        }
        return false;
    });

    $(document).on('change', '.js-search-volume-source', function () {
        let $settings = $('#ad-words-settings');
        let $volumeGroup = $('select[name="import_columns[monthly_searches]"]').closest('.form-group');
        if($(this).val() == 'ad_words_api') {
            $settings.show();
            $volumeGroup.hide();
        } else {
            $settings.hide();
            $volumeGroup.show();
        }
    });
});
