import consumer from './consumer'

consumer.subscriptions.create('ProjectActionsChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateMenu(data);
    },

    updateMenu(data) {
        let project = data.project;
        let $project = $(`[data-project=${project.id}]`);
        $project.find('ul.dropdown-menu').replaceWith(data.html);
    }
});
