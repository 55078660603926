import axios from 'axios';
import 'jquery';

const DELAY_TIME = 600;
let delayTimer;

const getUsers = (url, name, term) => {
    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
        const params = new URLSearchParams(window.location.search);
        params.set(name, term);
        axios.get(url, { params: params })
             .then((response) => {
                 $('#users-table').html(response.data);
                 history.replaceState({}, '', `${url}?${params.toString()}`);
             })
             .catch(() => showError('Something went wrong!'))
    }, DELAY_TIME);
};

$(document).on('input', '.js-users-search', function(){
    const url = $(this).closest('form').attr('action');
    const term = $(this).val();
    const name = $(this).attr('name');

    getUsers(url, name, term);
});