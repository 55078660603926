import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import 'channels';
import 'bootstrap/dist/js/bootstrap'
require('datatables.net-dt');
import 'datatables.net-dt/css/jquery.dataTables.css';
import '../components/toasts';
import '../components/dimension';
import '../components/projects';
import '../components/tasks';
import '../components/remaining-keywords';
import '../components/remaining-phrases';
import '../components/keywords';
import '../components/keywords-filters';
import '../components/segments';
import '../components/libraries';
import '../components/clients';
import '../components/click_rate_categories';
import '../components/recommended_dimensions';
import '../components/selected_libraries';
import '../components/users';
import '../components/header';
import '../components/competitors';
import '../stylesheets/application';
import axios from 'axios';

window.jQuery = $;
window.$ = $;

require('@nathanvda/cocoon');
const images = require.context('../images', true);
const token = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.headers.common['X-CSRF-Token'] = token;
axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
Rails.start();
ActiveStorage.start();
