import axios from 'axios';
import 'jquery';

const DELAY_TIME = 600;
let delayTimer;

const getCTRs = (url, name, term) => {
    clearTimeout(delayTimer);

    delayTimer = setTimeout(() => {
        let params = new URLSearchParams(window.location.search);
        params.set(name, term);
        axios.get(url, { params: params })
             .then((response) => {
                 $('#ctrs-table').html(response.data);
                 history.replaceState({}, '', `${url}?${params.toString()}`);
             })
             .catch(() => showError('Something went wrong!'))
    }, DELAY_TIME);
};

$(document).on('input', '.js-ctrs-search', function(){
    let $this = $(this);
    const url = $this.closest('form').attr('action');

    getCTRs(url, $this.attr('name'), $this.val());
});