import { Notyf } from "notyf";
import "notyf/notyf.min.css";

const DEFAULT_OPTIONS = { position: { x: 'center', y: 'top' }, duration: 5000 };

window.showInfo = (text) => {
    new Notyf(DEFAULT_OPTIONS).success(text);
}

window.showError = (text) => {
    new Notyf(DEFAULT_OPTIONS).error(text);
}
