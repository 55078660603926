import 'jquery';
import 'jquery-ui-bundle';
import axios from "axios";
import qs from 'qs';
import { loadingStarted, loadingEnded } from './loading-icon';

$(function () {
    $(document).ready(function () {
        $(document).on('click', '.js-segment-filter-hide', (e) => {
            let $this = $(e.currentTarget);
            let $segmentField = $this.closest('.js-segment-field');
            $segmentField.closest('table').find('.js-segment-filter-results').remove();
            $this.hide();
            $segmentField.find('.js-segment-filter-apply').show();
        });

        $(document).on('click', '.js-segment-keywords-delete', (e) => {
            let $this = $(e.currentTarget);
            if (confirm('Are you sure?')) {
                let $segmentField = $this.closest('.js-segment-field');
                let $resultsContainer = $segmentField.find('.js-segment-filter-results');
                axios.delete('/segment_keywords', { data: { segment_id: $segmentField.data('id') } })
                    .then((response) => {
                        $resultsContainer.find('.js-segment-keywords-panel').hide();
                        $segmentField.find('.js-segment-filter-hide').hide();
                        $segmentField.find('.js-segment-filter-apply').show();
                        showInfo('Keywords deleted');
                    })
                    .catch(() => showError('Something went wrong!'));
            } else {
                e.preventDefault();
            }
            return false;
        });

        $(document).on('ajax:success', '.js-segments-sort', function (event) {
            loadSegments($('#segments').data('src'));
            $('#segments-sort-btn').html(`Sort Segments by ${$(event.target).text()}<span class="caret"></span>`);
            return false;
        });

        $(document).on('click', '.js-segment-form-filter-apply', function () {
            $('.js-segment-apply-filter-input').val(true);
            $('.js-segment-form-submit').click();
            return false;
        });

        $(document).on('click', '#segments .pagination a', (e) => {
            let $this = $(e.currentTarget);
            loadSegments($this.attr('href'));
            return false;
        });

        $(document).on('click', '.js-segment-field', function() {
            let $this = $(this);
            markRow($this);
            loadPopupForm($this.closest('.js-segment-field').find('.js-edit-segment').attr('href'));
            return false;
        });

        $(document).on('click', '.js-segment-field .js-segment-actions', function(event) {
            event.stopPropagation();
        });

        $(document).on('click', '#segments-checker', function() {
            this.disabled = true;
            const checked = this.checked;
            const that = this;
            axios.patch('/excluded_segments', { dimension_id: $(this).data('dimension_id'), checked: checked })
                .then(function(response){
                    $('.js-segment-excluded-checkbox').attr('checked', checked);
                })
                .catch(function() {
                    showError('Something went wrong!');
                })
                .finally(() => {
                    that.disabled = false;
                });
        });

        $(document).on('click', '.js-new-segment', function() {
            loadPopupForm($(this).attr('href'));
            return false;
        });

        $(document).on('click', '.js-edit-segment', function() {
            let $this = $(this);
            markRow($this.closest('.js-segment-field'));
            loadPopupForm($this.attr('href'));
            return false;
        });

        const markRow = ($el) => {
            $el.css({ 'border': '2px solid #f39c12', 'background-color': '#ecf0f1' });
        }

        const unmarkRow = ($el) => {
            $el.css({ 'border': '', 'background-color': '' });
        }

        const loadPopupForm = (url) => {
            axios.get(url)
                .then(function(response){
                    let $popupForm = $('#segment-form-popup');
                    $popupForm.find('.modal-dialog').html(response.data);
                    $popupForm.modal('show');
                })
                .catch(function() {
                    showError('Something went wrong!');
                });
        }

        $(document).on('ajax:success', '#segments-form', function (event) {
            const data = event.detail[0];
            if(data.status === 'ok') {
                let onSuccess = data.apply_filter ? () => { segmentFilterApply($('.js-segment-field[data-id="'+ data.id + '"]').find('.js-segment-filter-apply')) }  : () => {};
                loadSegments($('#dimension-segments-table').data('href'), onSuccess);
                showInfo(data.message);
                let $segmentsCount = $('.js-dimension-segments-count');
                $segmentsCount.text(parseInt($segmentsCount.text()) + 1);
            }  else {
                $('#segments-form').replaceWith(data.html);
            }
            return false;
        });

        $(document).on('ajax:success', '.js-remove-segment', function (event) {
            loadSegments($('#dimension-segments-table').data('href'));
            showInfo('Segment was deleted');
            let $segmentsCount = $('.js-dimension-segments-count');
            $segmentsCount.text(parseInt($segmentsCount.text()) - 1);
            return false;
        });

        $(document).on('ajax:error', '#segments-form, .js-segments-sort, .js-remove-segment', function (e, data) {
            showError('Something went wrong!');
            return false;
        });

        $(document).on('click', '.js-segment-filter-apply', (e) => {
            segmentFilterApply($(e.currentTarget));
            return false;
        });

        const segmentFilterApply = ($el) => {
            $('.js-segment-filter-results').remove();
            $('.js-segment-filter-apply').show();
            $('.js-segment-filter-hide').hide();
            let $segmentField = $el.closest('.js-segment-field');
            let segmentId = $segmentField.data('id');
            let regexpValue = $segmentField.find('.js-segments-regex').text().trim();
            let negativeRegexpValue = $segmentField.find('.js-segments-negative-regex').text().trim();

            if (regexpValue.length > 0 || negativeRegexpValue.length > 0) {
                loadingStarted($el)
                axios.get('/segment_keywords', { params: { id: segmentId } })
                    .then((response) => {
                        const data = response.data;
                        if (data.count > 0) {
                            let $resultsContainer = $('<tr class="js-segment-filter-results"><td colspan="7"><div class="segment-keywords-panel js-segment-keywords-panel"></div></td></tr>');
                            $resultsContainer.insertAfter($segmentField);
                            $resultsContainer.find('.js-segment-keywords-panel').html(data.html);
                            let $table = $resultsContainer.find('.js-segment-keywords-table');
                            initSegmentKeywordsTable($table, `/project_keywords/${$table.data('project')}?segment_id=${$table.data('segment')}`);
                            $segmentField.find('.js-segment-filter-hide').show();
                            $el.hide();
                        } else {
                            showInfo('Keywords not found!');
                        }
                    })
                    .catch((error) => showError(error.response.data.error))
                    .finally(() => loadingEnded($el));
            } else {
                showError('Fill in at least one field("Matches" or "Does not match (regex)")');
            }
        }

        $(document).on('click', '.js-segment-keywords-show', function() {
            let $table = $('.js-segment-keywords-table');
            $('.js-segment-keywords-show').hide();
            $('.js-segment-keywords-table').DataTable().destroy();
            initSegmentKeywordsTable($table, `/project_keywords/${$table.data('project')}`);
            $('.js-segment-keywords-hide').show();
            return false;
        });

        $(document).on('click', '.js-segment-keywords-hide', (e) => {
            let $table = $('.js-segment-keywords-table');
            $('.js-segment-keywords-hide').hide();
            $('.js-segment-keywords-table').DataTable().destroy();
            initSegmentKeywordsTable($table, `/project_keywords/${$table.data('project')}?segment_id=${$table.data('segment')}`);
            $('.js-segment-keywords-show').show();
            return false;
        });

        const initSegmentKeywordsTable = (table, url) => {
            const dimensionName = table.data('dimension-name');
            const segmentName = table.data('segment-name');
            const segmentId = table.data('segment');
            $(table).DataTable({
                'ajax': url,
                'processing': true,
                'serverSide': true,
                'ordering': false,
                'columns': [
                    { 'data': 'name' },
                    { 'data': 'monthly_searches' }
                ],
                createdRow: function(row, data) {
                    if (segmentId) {
                        let segmentedData = data.segmented_data;
                        if(!segmentedData || segmentedData[dimensionName] != segmentName) $(row).css('background-color', '#ffdcdc');
                    }
                },
                fnDrawCallback: function(oSettings) {
                    if (oSettings._iDisplayLength >= oSettings.fnRecordsDisplay()) {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').hide();
                    } else {
                        $(oSettings.nTableWrapper).find('.dataTables_paginate').show();
                    }
                }
            });
        }

        const initSortable = () => {
            $('#dimension-segments-table tbody').sortable({
                handle: ".js-drag-segment",
                update: function(e, ui) {
                    let perPage = $('#per_page').val();
                    let pageNumber = $('#dimension-segments-table').data('current-page') || 1;
                    let currentIndex = ui.item.index() + 1;
                    let newPosition = (pageNumber - 1) * perPage + currentIndex;
                    axios.post(ui.item.data('set-position-src'), { position: newPosition, segment_id: ui.item.data('id') })
                        .catch(function(error){
                            showError('Something went wrong!');
                        });
                },
                helper: function(e, row) {
                    row.children().each(function() {
                        $(this).width($(this).width());
                    });
                    return row;
                }
            });
        }

        const loadSegments = (url, onSuccess = ()=> {}) => {
            axios.get(url, {
                params: { q: { name_i_cont: $('#search_segments').val() },
                          per_page: $('#per_page').val() },
                paramsSerializer: params => {
                    return qs.stringify(params)
                }})
                .then((response) => {
                    let segments = $('#segments');
                    const data = response.data;
                    segments.html(data.html);
                    toggleSorting();
                    initSortable();
                    segments.show();
                    setTimeout(onSuccess(), 5000);
                })
                .catch(() => showError('Something went wrong!'))
                .finally(() => $('#segment-form-popup').modal('hide'));
        };

        const toggleSorting = () => {
            $('.drag-icon').toggleClass('js-drag-segment', $('#search_segments').val() === '');
        };

        $('#segment-form-popup').on('hidden.bs.modal', function () {
            unmarkRow($('.js-segment-field'));
        });

        $('#per_page').on('change', function () {
            loadSegments($('#segments').data('src'));
        });

        initSortable();

        const DELAY_TIME = 600;
        let delayTimer;

        $(document).on('input', '#search_segments', function () {
            clearTimeout(delayTimer);

            delayTimer = setTimeout(() => {
                loadSegments($('#segments').data('src'));
            }, DELAY_TIME);
        });
    });
});

