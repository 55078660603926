import 'jquery';
import axios from 'axios';
import qs from 'qs';
import { loadingStarted, loadingEnded } from './loading-icon';

$(function() {
    const keywordFilters = $('#keyword-filters');
    $('.js-keywords-filters-apply').click((e) => {
        let $this = $(e.currentTarget);
        let $filtersApply = $('.js-keywords-filters-apply')
        $('.js-keywords-filters-hide').hide();
        $filtersApply.show();
        $('.js-keywords-filters-table').attr('data-active', false);
        $filtersApply.attr('disabled', true);
        let formGroup = $this.closest('.form-group');
        let filters = {
            dimension: { include_regex: $('#include_regex').val(), exclude_regex: $('#exclude_regex').val() },
            filter_name: formGroup.find('input').attr('name'),
            project: { project_exclude_regex: $('#project_exclude_regex').val(), project_include_regex: $('#project_include_regex').val() }
        }
        loadingStarted($this)
        axios.get(keywordFilters.data('src'),
                  { params: filters,
                    paramsSerializer: params => {
                        return qs.stringify(params, { arrayFormat: 'brackets' });
                    }
                  })
             .then((response) => {
                 $('.js-keywords-filters-table').html('');
                 const data = response.data;
                 let keywordFiltersTable = formGroup.find('.js-keywords-filters-table');
                 keywordFiltersTable.html(data.html);
                 keywordFiltersTable.attr('data-active', true);
                 $this.hide();
                 formGroup.find('.js-keywords-filters-hide').show();
             })
             .catch((error) => showError(error.response.data.error))
             .finally(() => {
                 loadingEnded($this);
                 $('.js-keywords-filters-apply').removeAttr('disabled');
             });
    });

    $('.js-keywords-filters-hide').click((e) => {
        let $this = $(e.currentTarget);
        let formGroup = $this.closest('.form-group')
        formGroup.find('.js-keywords-filters-table').html('');
        formGroup.find('.js-keywords-filters-apply').show();
        $this.hide();
    });

    $(document).ready(function() {
        $(document).on('click', '.js-keywords-filters-table .pagination a', (e) => {
            let $this = $(e.currentTarget);
            $('.js-keywords-filters-apply').attr('disabled', true);
            $('.js-keywords-filters-hide').attr('disabled', true);
            $('.pagination a').css('pointer-events', 'none');

            let keywordFiltersTable = $this.closest('.js-keywords-filters-table');
            axios.get($this.attr('href'))
                 .then((response) => {
                     keywordFiltersTable.html(response.data.html);
                 })
                .catch((e) => console.log(e))
                .finally(() => {
                    $('.js-keywords-filters-apply').attr('disabled', false);
                    $('.js-keywords-filters-hide').attr('disabled', false);
                    $('.pagination a').css('pointer-events', '');
                });
            return false;
        });
    });
});

