import consumer from './consumer'

consumer.subscriptions.create('ProjectReportGenerationChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateReportLink(data);
    },

    updateReportLink(data) {
        let report = data.report;
        let $report = $(`[data-report=${report.id}]`);
        $report.find('td.js-report-file-link').html(data.html);
    }
});
