import consumer from './consumer'

consumer.subscriptions.create('ProjectProgressChannel', {
    connected() {
        // Called when the subscription is ready for use on the server
    },

    disconnected() {
        // Called when the subscription has been terminated by the server
    },

    received(data) {
        this.updateCounters(data);
        this.updateProgress(data);
    },

    updateCounters(data) {
        let project = data.project;
        let $project = $(`[data-project=${project.id}]`);

        $project.find('.dimensions-counter').text(project.dimensions_count);
        $project.find('.segments-counter').text(project.segments_count);
        $project.find('.keywords-counter').text(project.keywords_count);
    },

    updateProgress(data) {
        let task = data.task;

        if (task.status === 'failure' || task.status === 'success' || task.status == 'cancel') {
            return this.removeTask(data);
        }

        if (task.status === 'waiting' || task.status === 'running') {
            return this.updateTask(data);
        }
    },

    removeTask(data) {
        let task = data.task;
        let $task = $(`[data-task=${task.id}]`);

        $task.remove();
    },

    updateTask(data) {
        let task = data.task;
        let $containers = $(`.tasks-list[data-container="${data.project.to_html_token}"]`);

        $containers.each(function(_, container){
            let $task = $(container).find(`[data-task=${task.id}]`);

            if ($task.length > 0) {
                $task.find('.progress-bar').css({width: `${task.progress}%`});
                $task.find('.progress-bar-message').text(task.message);
            } else {
                $(container).append(data.html);
            }
        });
    }
});
